<template>
    <div class="contact-us">
        <h1>Contact Us</h1>
        <br><br><br>
        <h3>Find us on:</h3>
        <h4>
        <img src="@/assets/instagram.svg" alt="Instagram" style="width: 40px; height: 40px; vertical-align: middle; margin: 0 10px;" /> <!-- Edit: Added vertical-align to align link with the middle of the image -->
        <a href="https://www.instagram.com/natashagadingkennel/" style="vertical-align: middle;">@natashagadingkennel</a>
        </h4>
        <h4>
        <img src="@/assets/youtube.svg" alt="YouTube" style="width: 40px; height: 40px; vertical-align: middle; margin: 0 10px;" /> <!-- Edit: Added vertical-align to align link with the middle of the image -->
        <a href="https://www.youtube.com/channel/UCrh3Rw3buz-5cun_Y-zqtEg/search?app=desktop" style="vertical-align: middle;">@NatashaGadingKennel</a>
        </h4>
        <h4>
        <img src="@/assets/tiktok.svg" alt="TikTok" style="width: 40px; height: 40px; vertical-align: middle; margin: 0 10px;" /> <!-- Edit: Added vertical-align to align link with the middle of the image -->
        <a href="https://www.tiktok.com/@natasha_gading_kennel" style="vertical-align: middle;">@natasha_gading_kennel</a>
        </h4>
        <h4>
        <img src="@/assets/logoX.svg" alt="X" style="width: 40px; height: 40px; vertical-align: middle; margin: 0 10px;" /> <!-- Edit: Added vertical-align to align link with the middle of the image -->
        <a href="https://x.com/NatashaGading" style="vertical-align: middle;">@NatashaGading</a>
        </h4>
        <br>
        <h3>For more information, contact us on
        <a href="https://wa.me/6281808600487">WhatsApp</a></h3>
    </div>
</template>

<script>
export default {
    name: 'Contact Us'
};
</script>

<style scoped>
.contact-us {
    padding: 20px;
    /* text-align: center; */
    font-family: Arial, sans-serif;
    margin-left: 20px;
    line-height: 1;
}

h1 {
    margin-bottom: 0px;
    text-align: center;
}

h5 {
    margin-bottom: 100px;
    color: #b4b4b4;
}


</style>
