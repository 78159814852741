<template>
    <div class="transaction-page">
        <h1>Transactions</h1>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <h2><span style="color: red;">will be available soon</span></h2>
    </div>
</template>

<script>
export default {
    name: 'Transactions'
};
</script>

<style scoped>
.transaction-page {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    line-height: 1;
}

h1 {
    margin-bottom: 0px;
}

h5 {
    margin-bottom: 100px;
    color: #b4b4b4;
}

h2 {
    margin-top: 30px;
    color: #333;
}
</style>
